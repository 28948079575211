
/* Full screen hero banner component */
<template>
<div class="error-splash h-screen flex items-center px-[20px]" v-if="content.error">
  <h1 class="h1 text-[#9F834C] !font-ivy font-[200] text-center text-[26px]">{{ content.errorMessage }}</h1>
</div>
<div class="hero-banner relative" v-if="!content.error">
  <div class="hero-banner__overlay"></div>

  <div class="hero-banner__content overflow-hidden">
      <nav class="navbar flex absolute top-0 right-0 w-full justify-between md:justify-between layer-[2] z-[2]">
    <div class="navbar__content hidden md:flex md:w-full">
    
      <ul class="navbar__menu flex flex-row gap-x-[16px] text-[#9F834C]">
        <li class="navbar__menu-item px-[8px] py-[8px] cursor-pointer" @click="scrollToSection('where')">Where</li>
        <li class="navbar__menu-item px-[8px] py-[8px] cursor-pointer" @click="scrollToSection('when')">When</li>
        <li class="navbar__menu-item px-[8px] py-[8px] cursor-pointer" @click="scrollToSection('order-of-service')"
        >Order of Service</li>
        <!-- <li class="navbar__menu-item border-[1px] border-[#9F834C] border-solid py-[8px] px-[18px]"
        @click="scrollToSection('rsvp')"
        >RSVP</li> -->
      </ul>
    </div>
    <!-- Mobile menu -->
    <div class="navbar__mobile-menu flex w-full justify-between md:self-end md:justify-end">
      <button class="navbar__mobile-menu-button md:hidden"
        @click.prevent="toggleMobileMenu"
      >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 8H28M4 16H28M4 24H28"
            stroke="#9F834C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <a class="rsvp-btn button border-[1px] border-[#9F834C] border-solid py-[8px] px-[18px] self-end text-[#9F834C] cursor-pointer"
      @click="scrollToSection('rsvp')"
      >RSVP</a>
    </div>
    <div class="mobile-menu block left-0 fixed h-screen w-screen z-[2] top-0 bg-white text-[#9F834C] p-[16px] transition-all duration-300 ease-in-out"
    :class="{ '!hidden': !content.mobileMenuActive }">
    <button class="close-mobile-menu absolute top-0 right-0 p-[16px]"
    @click.prevent="toggleMobileMenu"
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 8L24 24M8 24L24 8"
          stroke="#9F834C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
      <ul class="navbar__menu flex flex-col gap-y-[42px] text-[#9F834C] h-full items-center justify-center ">
        <li class="navbar__menu-item px-[8px] py-[8px] text-[36px] font-[500] font-ivy"
        @click="scrollToSection('where')"
        >Where</li>
        <li class="navbar__menu-item px-[8px] py-[8px] text-[36px] font-[500] font-ivy"
        @click="scrollToSection('when')"
        >When</li>
        <li class="navbar__menu-item px-[8px] py-[8px] text-[36px] font-[500] font-ivy"
        @click="scrollToSection('order-of-service')"
        >Order of Service</li>
        <li class="navbar__menu-item border-[1px] border-[#9F834C] border-solid py-[8px] px-[24px] text-[36px] font-[500] font-ivy bg-[#9F834C] text-white"
        @click="scrollToSection('rsvp')"
        >RSVP</li>
      </ul>
    </div>
  </nav>

  <div class="flex flex-col items-center justify-center md:pt-[80px] gap-y-[56px]">
    <div class="h4 !text-[18px] md:!text-[32px] text-[#9F834C] rellax !font-ivy !font-[100] italic tracking-[1.2px]" data-rellax-speed="3">
      <div class="inline-flex flex-row items-center justify-center w-full">
        <div class="not-italic font-[500] !text-[20px] min-h-[30px] w-full" v-if="content.guestsLoading">
            <div class="animate-pulse space-y-2">
                <div class="h-6 bg-[#C3AA8F] rounded-[8px]"></div>
            </div>
        </div>
        <p class="not-italic font-[500] md:!text-[32px] !text-[20px] min-h-[30px]" v-else>
        {{ content.guests.map(guest => guest.name).join(' & ') }}
        </p>
      </div>
      <div class="md:!text-[21px]">
      {{ content.intro.title }}
      </div>
    </div>
    <h1 class="h3 !text-[50px] md:!text-[50px] rellax !font-ivy !font-[100] tracking-[2px]" data-rellax-speed="4" v-once>
      {{ content.couple.bride.name }} <span>&</span><br> {{ content.couple.groom.name }}
    </h1>
    <h4 class="!text-[18px] md:!text-[21px] text-[#9F834C] flex flex-col rellax !font-ivy !font-[200]" data-rellax-speed="5">
      {{ content.details.date.svgHTML }} 
         <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-center self-center"></div>
       {{ content.venue.title }}
    </h4>

    <div class="image-container !w-[50px] md:w-[80px] rellax self-center" data-rellax-speed="5">
      <NuxtImg
        src="/waterorange.png"
        alt="Wedding hero banner"
        class="md:max-w-[80px]"
        format="webp"
      />
    </div>
    <!-- Chevron down icon  -->
    <div class="flex flex-col items-center justify-center absolute bottom-[16px] w-[32px] h-[32px] left-[50%] chevron-down-container transform -translate-x-1/2">
<svg xmlns="http://www.w3.org/2000/svg" fill="#9F834C" viewBox="0 0 18 18">
  <path fill="none" stroke="#9F834C" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="m1 5 8 8 8-8"/>
</svg>

    </div>
  </div>
  </div>

</div>
<div class="hero-banner-next" v-if="!content.error">
</div>
<div class="section rellax-wrapper mb-[56px] where" v-if="!content.error">
  <div class="section__content couple-background flex flex-wrap">
    <h2 class="w-full text-[#9F834C] !font-ivy font-[200] !text-[42px] mb-[72px] md:mb-[116px] text-left rellax rellax-wrapped md:px-[91px]" data-rellax-percentage="0.5" data-rellax-speed="-1">Where <span class="text-[46px]">.</span></h2>
    <div class="flex md:items-center md:justify-stretch">

      <div class="details flex md:hidden flex-col  gap-y-[16px] rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">

        <h3 class="h3 text-[#9F834C] !font-ivy font-[200] text-right">
          <strong>{{ content.venue.title }} </strong>
        </h3>
        <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-right self-end"></div>
        <p class="text-[#9F834C] !font-ivy font-[200] text-right" v-html="content.venue.detailedAddress">
        </p>
                <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-right self-end"></div>
                <div class="miles-from-palma text-[#9F834C] !font-ivy font-[200] text-right">
          <p class="">10.6 miles from Palma</p>
        </div>
        <div class="miles-from-soller text-[#9F834C] !font-ivy font-[200] text-right">
          <p class="">11.0 miles from Soller</p>
        </div>
                <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-right self-end"></div>
        <a class="text-[#9F834C] !font-ivy font-[200] text-[14px] text-right underline mb-[32px]" href="https://w3w.co/twirls.making.sketches" target="_blank">
          {{ content.venue.whatThreeWords }}
        </a>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12285.288075879458!2d2.6340675!3d39.6649703!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1297ed9dd8c8d2e9%3A0xffaf869b3f7194c!2sFinca%20Son%20Togores!5e0!3m2!1sen!2suk!4v1719389164253!5m2!1sen!2suk" :width="viewportWidthMinusPaddingX" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <div class="where-should-we-stay text-[#ffffff] !font-ivy font-[200] text-right bg-[#9F834C] p-[16px] rounded-[8px] mt-[32px]">
          <p class="md:text-[18px] text-[21px] font-[400] mb-[16px]">Where should we stay?</p>
              <a class="view-accommodation text-[#ffffff] !font-ivy font-[200] text-right underline" href="https://www.booking.com/searchresults.en-gb.html?label=gen173nr-1BCAEoggI46AdIM1gEaFCIAQGYAQm4AQfIAQzYAQHoAQGIAgGoAgO4AoDjz7QGwAIB0gIkN2EzNDI2YzMtZjEwNi00NGU1LTk0M2UtNzFiN2UzY2VkMWIx2AIF4AIB&sid=cf62306c81102512d81a2391dd1e0131&aid=304142&checkin=2025-08-24&checkout=2025-08-26&dest_id=767&dest_type=region&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&nflt=uf%3D-403203%3Buf%3D-395224" target="_blank">View accommodation close to the venue.</a>
              </div>
      </div>
      <div class="details  hidden md:flex flex-wrap  gap-y-[16px] rellax rellax-wrapped w-full md:px-[91px]" data-rellax-percentage="0.5" data-rellax-speed="-1" >
        <div class="w-[50%] order-1 flex flex-col">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12285.288075879458!2d2.6340675!3d39.6649703!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1297ed9dd8c8d2e9%3A0xffaf869b3f7194c!2sFinca%20Son%20Togores!5e0!3m2!1sen!2suk!4v1719389164253!5m2!1sen!2suk" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="desktop-iframe max-md:hidden"></iframe>
              <div class="where-should-we-stay text-[#ffffff] !font-ivy font-[200] text-right bg-[#9F834C] p-[16px] rounded-[8px] mt-[32px]">
          <p class="md:text-[18px] text-[21px] font-[400] mb-[16px]">Where should we stay?</p>
              <a class="view-accommodation text-[#ffffff] !font-ivy font-[200] text-right underline" href="https://www.booking.com/searchresults.en-gb.html?label=gen173nr-1BCAEoggI46AdIM1gEaFCIAQGYAQm4AQfIAQzYAQHoAQGIAgGoAgO4AoDjz7QGwAIB0gIkN2EzNDI2YzMtZjEwNi00NGU1LTk0M2UtNzFiN2UzY2VkMWIx2AIF4AIB&sid=cf62306c81102512d81a2391dd1e0131&aid=304142&checkin=2025-08-24&checkout=2025-08-26&dest_id=767&dest_type=region&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&nflt=uf%3D-403203%3Buf%3D-395224" target="_blank">View accommodation close to the venue.</a>
              </div>
        </div>
        <div class="w-[50%] flex flex-col order-0 md:items-start md:justify-center">
                  <h3 class="h3 text-[#9F834C] !font-ivy font-[200] text-left md:text-[18px]">
          <strong>{{ content.venue.title }} </strong>
        </h3>
        <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
        <p class="text-[#9F834C] !font-ivy font-[200] text-left md:text-[18px]" v-html="content.venue.detailedAddress">
        </p>
                <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
                <div class="miles-from-palma text-[#9F834C] !font-ivy font-[200] text-left">
          <p class="md:text-[18px]">10.6 miles from Palma</p>
        </div>
        <div class="miles-from-soller text-[#9F834C] !font-ivy font-[200] text-left">
          <p class="md:text-[18px]">11.0 miles from Soller</p>
        </div>
                <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
        <a class="text-[#9F834C] !font-ivy font-[200] text-[14px] text-left underline mb-[32px] md:text-[18px]" href="https://w3w.co/twirls.making.sketches" target="_blank">
          {{ content.venue.whatThreeWords }}
        </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section section-2 rellax-wrapper when flex md:hidden" v-if="!content.error">
  <div class="section__content couple-background flex flex-wrap md:!px-[91px]">
    <h2 class="w-full text-[#9F834C] !font-ivy font-[200] !text-[42px] mb-[62px] text-right rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">When <span class="text-[46px]">.</span></h2>
    <div class="flex flex-wrap flex-col w-full">
      <div class="details flex flex-col gap-y-[16px] mb-[32px] rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">
        <h3 class="h3 text-[#9F834C] !font-ivy font-[200] text-[26px] text-left">
          <strong>
            <!-- content.details.date.dateObject in the following format "Monday, August 25th, 2025" -->

             The Day.
          </strong>
        </h3>
        <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
        <p class="text-[#9F834C] !font-ivy font-[200] text-left text-[20px]">
                     {{ formattedDate }}
        </p>
      </div>
      <div class="details flex flex-col gap-y-[16px]">
        <h3 class="h3 text-[#9F834C] !font-ivy font-[200] text-right text-[26px]">
          <strong>
            <!-- content.details.date.dateObject in the following format "Monday, August 25th, 2025" -->
            The Time.
          </strong>
        </h3>
        <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-right self-end"></div>
        <p class="text-[#9F834C] !font-ivy font-[200] text-right text-[20px]">
        
            5pm (CEST)
        </p>
      </div>
    </div>
  </div>
</div>
<div  class="hidden md:flex items-center">
<div class="section section-3 bg-[#E4D3C1] hidden md:flex items-center justify-center rellax-wrapper py-[110px] overflow-hidden md:w-[50vw]" v-if="!content.error">
  <NuxtImg
    src="/thecouple.png"
    format="webp"
    alt="The couple" class="w-full max-w-[300px] h-auto rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-2"
  />
</div>
<div class="section section-2 rellax-wrapper when w-[50vw] hidden md:flex" >
  <div class="section__content couple-background flex flex-wrap md:!px-[91px]">
    <h2 class="w-full text-[#9F834C] !font-ivy font-[200] !text-[42px] mb-[62px] text-right rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">When <span class="text-[46px]">.</span></h2>
    <div class="flex flex-wrap flex-col w-full">
      <div class="details flex flex-col gap-y-[16px] mb-[24px] rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">
        <h3 class="h3 text-[#9F834C] !font-ivy font-[200] text-[26px] text-left">
          <strong>
            <!-- content.details.date.dateObject in the following format "Monday, August 25th, 2025" -->

             The Day.
          </strong>
        </h3>
        <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
        <p class="text-[#9F834C] !font-ivy font-[200] text-left text-[20px]">
                     {{ formattedDate }}
        </p>
      </div>
      <div class="details flex flex-col gap-y-[16px]">
        <h3 class="h3 text-[#9F834C] !font-ivy font-[200] text-right text-[26px]">
          <strong>
            <!-- content.details.date.dateObject in the following format "Monday, August 25th, 2025" -->
            The Time.
          </strong>
        </h3>
        <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-right self-end"></div>
        <p class="text-[#9F834C] !font-ivy font-[200] text-right text-[20px]">
        
            5pm (CEST)
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div class="section section-3 bg-[#E4D3C1] flex md:hidden items-center justify-center rellax-wrapper py-[110px] overflow-hidden" v-if="!content.error">
  <NuxtImg
    src="/thecouple.png"
    format="webp"
    alt="The couple" class="w-full max-w-[300px] h-auto rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-2"
  />
</div>
  <div class="section section-5 rellax-wrapper text-[#9F834C] order-of-service md:!mt-[91px]" v-if="!content.error">
    <div class="section__content couple-background flex flex-wrap">
      <h2 class="w-full text-[#9F834C] !font-ivy font-[200] !text-[42px] mb-[48px] text-right rellax rellax-wrapped md:text-center" data-rellax-percentage="0.5" data-rellax-speed="-1">Order of<br class="md:hidden"> Service <span class="text-[46px]">.</span></h2>
      <div class="flex flex-wrap flex-col w-full md:w-[60vw] md:items-center md:justify-center md:mx-auto">
        <div class="details flex flex-col gap-y-[16px] mb-[32px] rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">
          <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
          <p class="text-[#9F834C] !font-ivy font-[200] text-left text-[20px]">
            <strong>Here's a sneak peak of what to expect on the day.</strong>
          </p>
          <div class="order-service order-service__container mt-[36px] flex flex-col gap-y-[40px] items-start justify-center">
            <div class="order-service__item flex flex-row items-center justify-start w-full gap-x-[12px]">
              <div class="order-service__text-container">
              <div class="bold text-bold font-[500] order-service__time text-[#9F834C] !font-ivy font-[200] text-[17px] pr-[0px]">5:00pm</div>
              <div class="order-service__description text-[#9F834C] !font-ivy font-[200] text-[17px]">Arrival &<br> Welcome Drinks</div>
              </div>
              <div class="order-service__icon w-[70px] h-[70px] ml-auto">
                <NuxtImg
                  src="/cocktailsicon.png"
                  fit="cover"
                  class="w-[70px] h-[70px] object-contain object-center"
                  format="webp"/>
              </div>
            </div>
            <div class="order-service__item flex flex-row items-center justify-start w-full gap-x-[12px]">
              <div class="order-service__text-container order-1">
              <div class="bold text-bold font-[500] order-service__time text-[#9F834C] !font-ivy font-[200] text-[17px] pr-[0px] order-2">5:30pm</div>
              <div class="order-service__description text-[#9F834C] !font-ivy font-[200] text-[17px] order-1">Ceremony</div>
              </div>
              <div class="order-service__icon w-[70px] h-[70px] mr-auto order-0">

              <NuxtImg
                  src="/ceremonyicon.png"
                  fit="cover"
                  class="w-[70px] h-[70px] object-contain object-center"
                  format="webp"/>
              </div>
            </div>
            <div class="order-service__item flex flex-row items-center justify-start w-full gap-x-[12px]">
              <div class="order-service__text-container">
              <div class="bold text-bold font-[500] order-service__time text-[#9F834C] !font-ivy font-[200] text-[17px] pr-[0px]">6:00pm</div>
              <div class="order-service__description text-[#9F834C] !font-ivy font-[200] text-[17px]">Aperitifs</div>
              </div>
              <div class="order-service__icon w-[70px] h-[70px] ml-auto">
   
              <NuxtImg
                  src="/snacksicon.png"
                  fit="cover"
                  class="w-[70px] h-[70px] object-contain object-center"
                  format="webp"/>
              </div>
            </div>
            <div class="order-service__item flex flex-row items-center justify-start w-full gap-x-[12px]">
              <div class="order-service__text-container order-1">
              <div class="bold text-bold font-[500] order-service__time text-[#9F834C] !font-ivy font-[200] text-[17px] pr-[0px] order-2">7:00pm</div>
              <div class="order-service__description text-[#9F834C] !font-ivy font-[200] text-[17px] order-1">Dinner</div>
              </div>
              <div class="order-service__icon w-[70px] h-[70px] mr-auto order-0">
              <NuxtImg
                  src="/dinnericon.png"
                  fit="cover"
                  class="w-[70px] h-[70px] object-contain object-center"
                  format="webp"/>
              </div>
            </div>
            <div class="order-service__item flex flex-row items-center justify-start w-full gap-x-[12px]">
              <div class="order-service__text-container">
              <div class="bold text-bold font-[500] order-service__time text-[#9F834C] !font-ivy font-[200] text-[17px] pr-[0px]">9:00pm</div>
              <div class="order-service__description text-[#9F834C] !font-ivy font-[200] text-[17px]">Open Bar &<br> Entertainment</div>
              </div>
              <div class="order-service__icon w-[70px] h-[70px] ml-auto">
              <NuxtImg
                  src="/discoicon.png"
                  fit="cover"
                  class="w-[70px] h-[70px] object-contain object-center"
                  format="webp"/>
              </div>
            </div>
            <div class="order-service__item flex flex-row items-center justify-start w-full gap-x-[12px]">
              <div class="order-service__text-container order-1">
              <div class="bold text-bold font-[500] order-service__time text-[#9F834C] !font-ivy font-[200] text-[17px] pr-[0px] order-2">1:00am</div>
              <div class="order-service__description text-[#9F834C] !font-ivy font-[200] text-[17px] order-2">Finish</div>
              </div>
              <div class="order-service__icon w-[70px] h-[70px] mr-auto order-0">
              <NuxtImg
                  src="/taxiicon.png"
                  fit="cover"
                  class="w-[70px] h-[70px] object-contain object-center"
                  format="webp"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- Section 6 - RSVP -->
  <div class="section section-6 rellax-wrapper rsvp md:w-[60vw] md:mx-auto" v-if="!content.error">
    <div class="section__content couple-background flex flex-wrap">
      <h2 class="w-full text-[#9F834C] !font-ivy font-[200] !text-[42px] mb-[48px] text-left rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">RSVP <span class="text-[46px]">.</span></h2>
      <div class="flex flex-wrap flex-col w-full">
        <div class="details flex flex-col gap-y-[16px] mb-[32px] rellax rellax-wrapped" data-rellax-percentage="0.5" data-rellax-speed="-1">
          <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
          <p class="text-[#9F834C] !font-ivy font-[200] text-left text-[20px]">
            Please RSVP by September 25th, 2024.
          </p>
          <div class="rsvp-form flex flex-col gap-y-[16px]" v-if="!content.successMessage">
            <input class="attending appearance-none" type="radio" id="attending" name="attending" :value="true" v-model="content.formData.attending">
            <label for="attending" class=" attendence-label text-[#9F834C] !font-ivy font-[200] text-[18px]">I am attending 🙂</label>
            <input class="not-attending appearance-none" type="radio" id="not-attending" name="attending" :value="false" v-model="content.formData.attending" @change="updateAllGuestsRSVP">
            <label for="not-attending" class="attendence-label text-[#9F834C] !font-ivy font-[200] text-[18px]">I am not attending ☹️</label>
            <button v-if="content.formData.attending == false" @click.prevent="handleRSVPSubmit" type="submit" class="rsvp button border-[1px] border-[#9F834C] border-solid py-[8px] px-[18px] self-end text-[#ffffff] mt-[16px] w-full bg-[#9F834C]">
              Submit</button>
          </div>
            <div class="success-message" v-show="content.successMessage">
              <p class="text-[#9F834C] !font-ivy font-[500] text-[28px] text-center">Thank you for RSVPing!<br> To change anything, please refresh this page.</p>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Section 4 - Dietary requirements -->
  <div class="section section-4 md:w-[60vw] md:mx-auto" v-if="!content.error && !content.successMessage" :class="{ 'hidden': !content.showDietaryRequirements, 'flex': content.showDietaryRequirements }" ref="section4">
    <div class="section__content couple-background flex flex-wrap">
      <h2 class="w-full text-[#9F834C] !font-ivy font-[200] !text-[42px] mb-[48px] text-right" >Dietary<br> Requirements <span class="text-[46px]">.</span></h2>
      <div class="flex flex-wrap flex-col w-full">
        <div class="details flex flex-col gap-y-[16px] mb-[32px]" >

          <div class="divider w-[20%] h-[1px] bg-[#9F834C] my-[8px] opacity-[30%] text-left self-start"></div>
          <p class="text-[#9F834C] !font-ivy font-[200] text-left text-[20px]">
                      Please let us know if you have any dietary requirements.
          </p>
          <div class="dietary-requirements-form flex flex-col gap-y-[16px]">
            <!-- Select with guest names populated from the data and on change, update the selectedGuest ref, and show the dietary requirements form -->
            <select id="guest-name" name="guest-name" class="guest-name text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px] text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px] mb-[16px]"
            @change="updateSelectedGuest($event)">
              <option v-for="(guest, i) in content.guests" :value="guest.name" :key="i" :selected="i == 0 ? true : false">{{ guest.name }}</option>
            </select>
            <div v-show="content.selectedGuest != null" class="grid grid-cols-2 gap-x-[16px] gap-y-[32px]" id="dietary-requirements" >
              <div class="flex flex-row items-center justify-start gap-y-[8px]" v-for="(label, key) in content.food.labels" :key="key">
                <label :for="key" class="text-[#9F834C] !font-ivy font-[200] text-[18px] order-1 ml-[8px]">{{ label }}</label>
                <input :id="key" type="checkbox" v-model="content.food.dietaryRequirements[key]" class="text-[#9F834C] !font-ivy font-[200] order-0" @change="updateGuestDietaryRequirements($event)">
              </div>
            </div>
            <div class="nut-severity-textarea flex w-full flex-col gap-y-[16px] mt-[10px]" v-if="content.food.dietaryRequirements.nutFree">
              <label for="nut-severity" class="text-[#9F834C] !font-ivy font-[200] text-[16px]">If you have a nut allergy, please specify the severity</label>
              <textarea id="nut-severity" class="text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px] text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px]" v-model="content.food.dietaryRequirements.nutAllergySeverity" @change="updateGuestDietaryRequirements($event)"></textarea>
            </div>
            <div class="other-info flex w-full flex-col gap-y-[16px] mt-[10px]" v-if="content.food.dietaryRequirements.other">
              <label for="other-info" class="text-[#9F834C] !font-ivy font-[200] text-[16px]">Please specify any other dietary requirements</label>
              <textarea id="other-info" class="text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px] text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px]" v-model="content.food.dietaryRequirements.otherDetails" @change="updateGuestDietaryRequirements($event)"></textarea>
           
            </div>
              <div class="flex items-center max-md:mt-[40px] flex-col" v-if="content.selectedGuest != null">
                <h4 class="text-[#9F834C] !font-ivy font-[400] text-[21px] text-center mb-[32px]">We'd love for you to add a special song request to our playlist 👇🏼</h4>
                <p class="text-[#9F834C] !font-ivy font-[200] text-[16px] text-center">Please add your song request into the text box below. Or click on the link to add your song request to our Spotify playlist.</p>
                <textarea type="textarea" class="text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px] text-[#9F834C] !font-ivy font-[200] border-[1px] border-solid w-full border-[#9F834C] p-[8px] rounded-[8px] mt-[16px] mb-[24px]" 
                v-model="content.formData.songrequest"
                placeholder="Song request"></textarea>
                <div class="or text-[#9F834C] !font-ivy font-[200] text-[16px] text-center mb-[16px]">OR</div>
                <p class="linktospotify text-[#9F834C] !font-ivy font-[200] text-[16px] text-center underline mb-[24px]"><a href="https://open.spotify.com/playlist/3uGlRSEZdNK4ziz2eMjFSQ?si=OQYFKUAQQQ6EgEo3fVRa5g&pt=222ff3fb2ff1f589bbad905557c00888&pi=e-kQOOqE32S6KF" target="_blank">Add your song request to our Spotify playlist</a></p>
                <div id="embed-iframe"></div>
              </div>
                          <button @click.prevent="handleRSVPSubmit" type="submit" class="rsvp button border-[1px] border-[#9F834C] border-solid py-[8px] px-[18px] self-end text-[#ffffff] mt-[16px] w-full bg-[#9F834C] text-[18px]" v-if="content.selectedGuest != null">
              Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
/* import rellax.min.js file from node_modules */
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import Rellax from 'rellax/rellax.min.js';
import { format } from 'date-fns';
import * as Sentry from "@sentry/vue";
import Hotjar from '@hotjar/browser';
// import DeviceDataLogger from './components/DeviceDataLogger';



// import { NuxtImg } from 'nuxt-image';
const myApp = {
  setup() {
    const currentPath = ref(useRoute().fullPath.replace('/',''));
  
    const currentSection = ref(1);

    const img = useImage();

    const imageUrls = [
"1--dG9tLWFtZXJ5LWxhdXJh.png",
"2--dG9tLWFtZXJ5LWxhdXJh.png",
"3--c2FtLXJhaW5lLXNhbS1yYWluZS1wbHVzb25l.png",
"4--c2FtLXJhaW5lLXNhbS1yYWluZS1wbHVzb25l.png",
"6--c2Vhbi1kaXhvbi1zZXJlbmE%3D.png",
"7--ZGF2aWQtY2FyZXR0ZS1hbGljaWEtbWFl.png",
"8--ZGF2aWQtY2FyZXR0ZS1hbGljaWEtbWFl.png",
"9--bWljaGVsbGUtYnJlbm5hbg%3D%3D.png",
"10--YXVudGllLWFuZ2VsYS1pYW4%3D.png",
"11--YXVudGllLWFuZ2VsYS1pYW4%3D.png",
"12--YXVudGllLWhlbGVu.png",
"13--YXVudGllLWphY2tpZS11bmNsZS10aW0%3D.png",
"14--YXVudGllLWphY2tpZS11bmNsZS10aW0%3D.png",
"15--YWRhbS1ub3J0b24tc2FtLW5vcnRvbg%3D%3D.png",
"16--YWRhbS1ub3J0b24tc2FtLW5vcnRvbg%3D%3D.png",
"17--Z2VvcmdpYS1sZWRidXJ5LW1hdHQ%3D.png",
"18--Z2VvcmdpYS1sZWRidXJ5LW1hdHQ%3D.png",
"19--bWFyaWEtZGFu.png",
"20--bWFyaWEtZGFu.png",
"21--YXVudGllLWNsYXJlLW1hcms%3D.png",
"22--YXVudGllLWNsYXJlLW1hcms%3D.png",
"23--c29waGllLXNuYXBlLWJlbg%3D%3D.png",
"24--c29waGllLXNuYXBlLWJlbg%3D%3D.png",
"25--aGFubmFoLWpha2U%3D.png",
"26--aGFubmFoLWpha2U%3D.png",
"27--Y2hyaXMtdGFzaA%3D%3D.png",
"28--Y2hyaXMtdGFzaA%3D%3D.png",
"29--bGV3aXMtbW9sbHk%3D.png",
"30--bGV3aXMtbW9sbHk%3D.png",
"31--ZW1pbHktemFjaC1pcmlz.png",
"32--ZW1pbHktemFjaC1pcmlz.png",
"33--ZW1pbHktemFjaC1pcmlz.png",
"34--YWxleC1tb29keS1taXJhbmRh.png",
"35--YWxleC1tb29keS1taXJhbmRh.png",
"36--amFrZS1iZWVjaC1hbm5h.png",
"37--amFrZS1iZWVjaC1hbm5h.png",
"38--bGV4LWhhZmZuZXItZ2VtbWE%3D.png",
"39--bGV4LWhhZmZuZXItZ2VtbWE%3D.png",
"40--ZGF2ZS1odXNzZWxsLWplbg%3D%3D.png",
"41--ZGF2ZS1odXNzZWxsLWplbg%3D%3D.png",
"42--bmlnZWwta2F0cmlu.png",
"43--bmlnZWwta2F0cmlu.png",
"44--aGFubmFoLXRvbS1yb2Jpbg%3D%3D.png",
"45--aGFubmFoLXRvbS1yb2Jpbg%3D%3D.png",
"46--aGFubmFoLXRvbS1yb2Jpbg%3D%3D.png",
"47--bHlkaWE%3D.png",
"48--bWFkZGll.png",
"49--ZWxsaXMtc29maWE%3D.png",
"50--ZWxsaXMtc29maWE%3D.png",
"51--Y2Fyb2xpbmUtamlt.png",
"52--Y2Fyb2xpbmUtamlt.png",
"53--bGl6LWxpei1wbHVzb25l.png",
"54--bGl6LWxpei1wbHVzb25l.png",
"55--a2lyc3R5LWthcmw%3D.png",
"56--a2lyc3R5LWthcmw%3D.png",
"57--a2F6LWthei1wbHVzb25l.png",
"58--a2F6LWthei1wbHVzb25l.png",
"59--ZW1pbHktbGV3aXMtYXJ0aHVy.png",
"60--ZW1pbHktbGV3aXMtYXJ0aHVy.png",
"61--ZW1pbHktbGV3aXMtYXJ0aHVy.png",
"62--Z2FpbC1yaWNoYXJk.png",
"63--Z2FpbC1yaWNoYXJk.png",
"64--cG9wcHktbHVrZQ%3D%3D.png",
"65--cG9wcHktbHVrZQ%3D%3D.png",
"66--aGVsZW4tbGVvbg%3D%3D.png",
"67--aGVsZW4tbGVvbg%3D%3D.png",
"68--aGFycmlldC1sZXdpcw%3D%3D.png",
"69--aGFycmlldC1sZXdpcw%3D%3D.png",
"70--em9lLXBldGU%3D.png",
"71--em9lLXBldGU%3D.png",
"72--bGF1cmEtbHVrZQ%3D%3D.png",
"73--bGF1cmEtbHVrZQ%3D%3D.png",
"74--d2VuZHktamFtZXM%3D.png",
"75--d2VuZHktamFtZXM%3D.png",
"76--am9lLW5laWw%3D.png",
"77--am9lLW5laWw%3D.png",
"78--sammy-bridalparty.png",
"79--sammy-bridalparty.png",
"80--kish-groomparty.png",
"81--kish-groomparty.png",
"82--murialjohn-groomparty.png",
"83--murialjohn-groomparty.png",
"84--tina-cam.png",
"85--tina-cam.png"
    ]

    try {
    let imgUrls = [];
    for (let i = 0; i < 85; i++) {
      const imgUrl = img(imageUrls[i], { width: 600, height: 600, format: 'webp', quality: 100});
      imgUrls.push(imgUrl);
    }

    
    } catch (error) {
      console.error('Error:', error);
    }
useHead({
  link: [
    {
      rel: 'stylesheet',
      preload: true,
      href: 'https://use.typekit.net/ioh6fxi.css'
    }
  ]
});
    const content = ref({
      formData: {
        attending: false,
        songrequest: ""
      },
      previouslyRSVPd: null,
      justMounted: false,
      mobileMenuActive: false,
      error: null,
      showDietaryRequirements: false,
      errorMessage: "",
      guestsLoading: true,
      hasplusone: false,
      successMessage: false,
      guestGroup: {
        id: 6
      },
            formData: {
        name: '',
        email: '',
        message: '',
      },
      intro: {
        title: "You're invited to the wedding of"
      },
      venue: {
        title: "Finca Son Togores, Mallorca",
        detailedAddress: "Crtra. Palma-Valldemossa km.<br> 10-200 Esporles, Mallorca<br> 39.664971, 2.633543",
        whatThreeWords: "twirls.making.sketches"
      },
      couple: {
        bride: {
          name: "Lucy"
        },
        groom: {
          name: "Callum"
        }
      },
      misc: {
        coupleSRC: "../thecouple.png",
      },
      details: {
        date: {
          svgHTML: `25 / 08 / 2025`,
          dateObject: new Date(2025, 7, 25)
        }
      },
      food: {
        dietaryRequirements: {
          vegetarian: false,
          vegan: false,
          glutenFree: false,
          dairyFree: false,
          nutFree: false,
          nutAllergySeverity: "",
          other: false,
          otherDetails: ""
        },
        labels: {
          vegetarian: "Vegetarian",
          vegan: "Vegan",
          glutenFree: "Gluten-free",
          dairyFree: "Dairy-free",
          nutFree: "Nut-free",
          other: "Other"
        }
      },
      guests: [
        {
          name: "",
          dietary_restrictions: {
            vegetarian: true,
            vegan: false,
            glutenFree: false,
            dairyFree: false,
            nutFree: false,
            nutAllergySeverity: ""
          },
          songrequest: ""
        }
 
      ],
      selectedGuest: null
    });


  const isMobile = computed(() => {
    return window.innerWidth < 768;
  });



    const viewportWidth = ref(0);
    
    const viewportWidthMinusPaddingX = computed(() => {
      const paddingX = 20 * 2;
      return viewportWidth.value - paddingX;
    });

    const formattedDate = computed(() => {
      return format(content.value.details.date.dateObject, 'EEEE, MMMM do, yyyy');
    });

    const updateAllGuestsRSVP = () => {
      content.value.guests = content.value.guests.map(guest => {
        return {
          ...guest,
          rsvp: content.value.formData.attending,
          songrequest: content.value.formData.songrequest
        };
      });
    };


    const updateGuestDietaryRequirements = (event) => {
/* For specific guest update the dietary restrictions */
  try {
      const selectedGuest = content.value.guests.find(guest => guest.name === content.value.selectedGuest);
      content.value.food.dietaryRequirements = selectedGuest.dietary_restrictions;
      /* update the guest within the guests array */
      const guestIndex = content.value.guests.findIndex(guest => guest.name === content.value.selectedGuest);
      content.value.guests[guestIndex] = selectedGuest;
    } catch (error) {
      console.error('Error:', error);
    }
      
    };


    const scrollToSection = async (val) => {
      const sectionRef = document.querySelector(`.${val}`);
      sectionRef.scrollIntoView({behavior: 'smooth'});
      content.value.mobileMenuActive = false;
      document.body.style.overflow = 'auto';
    };

    const handleRSVPSubmit = async () => {
      try {
        /* create a bodyToSend object that contains the guests content */
        const bodyToSend = {
          group_id: content.value.guestGroup.id,
          guests: content.value.guests
        };
        const {data} = await useFetch('/api/rsvp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyToSend)
        });
        const text = data.value; 
        
        try {
          const result = text;
          
          
          content.value.successMessage = true;
          
          
        } catch (e) {
          console.error('Failed to parse JSON:', text); // Log the full response text
        }
      } catch (error) {
        console.error('Error:', error);
        content.value.error = error;
        content.value.errorMessage = "Please try submitting your RSVP again. If the issue persists, please contact the bride or groom."
      }
    }

    const toggleMobileMenu = () => {
      
      content.value.mobileMenuActive = !content.value.mobileMenuActive;
      content.value.mobileMenuActive ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    };

   

    const updateViewportWidth = () => {
      viewportWidth.value = window.innerWidth;
    };

    const updateSelectedGuest = (event) => {
      /* update the selectedGuest ref with the value of the selected option */
      content.value.selectedGuest = event.target.value;
      /* update the dietary requirements form with the selected guest's dietary requirements */
      const selectedGuest = content.value.guests.find(guest => guest.name === content.value.selectedGuest);
      content.value.food.dietaryRequirements = selectedGuest.dietary_restrictions;
      
      content.value.formData.songrequest = selectedGuest.songrequest;
      
      

    };


  const setPendingAttending = async (val) => {
    /* check if content.value.guests has a pending value of true for any guest and if so console log the guest */
    const rsvpGuests = content.value.guests.some(guest => guest.rsvp === true);
    
    
    if (rsvpGuests && content.value.previouslyRSVPd) {
      return;
    }
    try {
        const bodyToSend = {
          group_id: content.value.guestGroup.id,
          guests: content.value.guests,
          pending: val
        };
       const {data} = await useFetch('/api/pending', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyToSend)
        });
        const text = data.value; 
        
        try {
          const result = text;
          console.log('Pending Result:', result);
        } catch (e) {
          console.error('Failed to parse JSON:', text); // Log the full response text
        }
    } catch (error) {
      console.error('Error PENDING:', error);
    }
  }

/* watch any changes to the formData.attending value */
    watch(() => content.value.formData.attending, (newValue, oldValue) => {
      if (content.value.guests.some(guest => guest.rsvp === true)) {
        
        content.value.previouslyRSVPd = true;
      } else {
        
        content.value.previouslyRSVPd = false;
      }
      if (newValue === true) {
        content.value.showDietaryRequirements = true;
        updateGuestDietaryRequirements();
        if (content.value.formData.attending) {
        try {
 
          window.onSpotifyIframeApiReady = (IFrameAPI) => {
            const element = document.getElementById('embed-iframe');
            const options = {
              uri: 'spotify:playlist:3uGlRSEZdNK4ziz2eMjFSQ?si=a83d08fa4f55450b',
              className: 'spotify-embed',
            };
            const callback = (EmbedController) => {
              EmbedController.addListener('ready', () => {
                console.log('Spotify Embed is ready');


              });
            };
            IFrameAPI.createController(element, options, callback);

          };
   
      } catch (error) {
        console.error('Error:', error);
      }
}
        updateSongRequest();
        /* Update all guests in guests to have attending set to true */
        content.value.guests = content.value.guests.map(guest => {
          return {
            ...guest,
            rsvp: true,
          };
        });
        /* scroll to the dietary requirements section, by ref */
        setTimeout(() => {
          if (!content.value.justMounted) {
          /* get the section 4 ref */
          const sec4 = document.querySelector('.section-4');
          
          /* scroll to the section 4 ref */
          sec4.scrollIntoView({behavior: 'smooth'});
          } else {
            content.value.justMounted = false;
          }
        }, 200);


        
      } else {
        content.value.showDietaryRequirements = false;
      }
      setPendingAttending(newValue);
    });

    watch(() => content.value.formData.songrequest, (newValue, oldVal) => {
      if (newValue !== oldVal) {
        

        updateSongRequest(newValue);
      }
    });

/* default param val is empty string */
    const updateSongRequest = (val = "") => {
      const selectedGuest = content.value.guests.find(guest => guest.name === content.value.selectedGuest);
      selectedGuest.songrequest = val;
      const guestIndex = content.value.guests.findIndex(guest => guest.name === content.value.selectedGuest);
      content.value.guests[guestIndex] = selectedGuest;
      
    };

    const handleSubmit = async () => {
      const guestObjWithDietaryRequirements = {
        name: content.value.selectedGuest,
        dietary_restrictions: content.value.food.dietaryRequirements,
        group_id: content.value.guestGroup.id,

      };
      
      try {
        const {data} = await useFetch('/api/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(guestObjWithDietaryRequirements)
        });
        const text = data.value; 
        
        try {
          const result = text;
          
        } catch (e) {
          console.error('Failed to parse JSON:', text); // Log the full response text
        }
      } catch (error) {
        console.error('Error BRO:', error);

      }
    }


    const recordSessionView = async (guestGroupId) => {
  try {
    const response = await fetch('/api/sessionview', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ guest_group_id: guestGroupId }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('View recorded successfully. New view count:', data.session_view);
  } catch (error) {
    console.error('Error recording view:', error);
  }
}




    const addPathToLocalStorage = () => {
      const path = currentPath.value;
      if (!path) {
        return;
      } 
      if (localStorage) {
      localStorage.setItem('guestpath', path);
      }
    }

    const setGroupIDByPathDBLookup = async () => {
// Get the path from local storage
const localStoragePath = localStorage.getItem('guestpath');

// Get the current route object
const route = useRoute();

// Extract the search parameter from the URL

/* for each entry in the searchParams object, log the key and value */
let newPath = route.fullPath.replace('/','').replace('?','');

// Set the currentPath.value based on conditions
if (newPath !== '') {
  currentPath.value = newPath.replace('?','');
} else if (localStoragePath) {
  currentPath.value = localStoragePath.replace('?','');
}

      
      try {
        
        const {data} = await useFetch('/api/guestgroups', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({path: currentPath.value.replace('?','')})
        });
        const text = data.value; 
        
        try {
          /* if any guests in text has a value of true for the 'hasplusone' key, then set content.value.hasplusone to true */
          
          const guestsWithPlusOne = text.some(guest => guest.hasplusone === true);
          content.value.hasplusone = guestsWithPlusOne;


          const result = text[0].group_id;
          
          content.value.guestGroup.id = result;
          /* if the guests has at least one guest with a plus one, then order the guests such that the one with a plus one is last */
          if (content.value.hasplusone) {
            const guestsWithPlusOne = text.filter(guest => guest.hasplusone === true);
            const guestsWithoutPlusOne = text.filter(guest => guest.hasplusone === false);
            content.value.guests = [...guestsWithoutPlusOne, ...guestsWithPlusOne];
           /* change the name of the guest with a plus one to "Guest +1" */
            content.value.guests[content.value.guests.length - 1].name = "Plus One";
          } else {
            content.value.guests = text;
          }
          /* Order guests by id */
          content.value.guests.sort((a, b) => a.id - b.id);
          // content.value.guests = text;
          addPathToLocalStorage();
          content.value.guestsLoading = false;
          content.value.selectedGuest = content.value.guests[0].name;

        } catch (e) {
          console.error('Failed to parse JSON why:', e); // Log the full response text
        }
      } catch (error) {
        console.error('Error LOL:', error);
                        content.value.error = error;
                        content.value.errorMessage = "Please try scanning your QR code again. If the issue persists, please contact the bride or groom."
        
    

      }
    }

    onMounted(async () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-1CQF3FN4LE');
    
      await setGroupIDByPathDBLookup();
      
      recordSessionView(content.value.guestGroup.id);
      updateViewportWidth(); // Call the method to set the initial value
      window.addEventListener('resize', updateViewportWidth); // Add event listener for window resize

      const rellax = new Rellax('.rellax');
      rellax.refresh();
      const siteId = 5058914;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
      // getGuests();
      /* Check if guests have at least one rsvp value of true and if so set the formData.attending to true */
       await nextTick();
       setTimeout(() => {
      const hasGuestsRSVPd = content.value.guests.some((guest) => {
        
        return guest.rsvp === true;
      });
      const hasGuestsRSVPdFalse = content.value.guests.some((guest) => {
        
        return guest.rsvp === false;
      });
      
      if (hasGuestsRSVPd) {
        content.value.justMounted = true;
        content.value.formData.attending = true;
      } else if (hasGuestsRSVPdFalse) {
        content.value.justMounted = true;
        content.value.formData.attending = false;
      }
      const guestHasASongRequest = content.value.guests.some((guest) => {
        return guest.songrequest !== "";
      });
      if (guestHasASongRequest) {
        const guestWithSongRequest = content.value.guests.find((guest) => {
          return guest.songrequest !== "";
        });
        

        content.value.formData.songrequest = guestWithSongRequest.songrequest;
        
 
      }


    }, 800);

    });

    onBeforeMount(() => {
      /* Set currentPath ref to the current path */
      setGroupIDByPathDBLookup();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateViewportWidth); // Clean up the event listener
    });

    return {
      currentSection,
      content,
      viewportWidth,
      viewportWidthMinusPaddingX,
      formattedDate,
      updateViewportWidth,
      updateSelectedGuest,
      handleSubmit,
      handleRSVPSubmit,
      scrollToSection,
      toggleMobileMenu,
      updateGuestDietaryRequirements,
      updateAllGuestsRSVP,
      setPendingAttending,
      recordSessionView
    };
  }
};
Sentry.init({
  myApp,
  dsn: "https://1a5b01fdee633f01b066187290581227@o4507566213038080.ingest.de.sentry.io/4507599447523408",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled. https://weddinglucycallum-4da1524510c2.herokuapp.com/
  tracePropagationTargets: ["localhost", /^https:\/\/weddinglucycallum-4da1524510c2.herokuapp.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
export default myApp;
</script>

<style>
/* Import font family from assets folder */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Dream Avenue', sans-serif;
}
li {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
}

.image-container {
      height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-banner {
    height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  position: relative;
}

.hero-banner-next {

  background-image: url('../sontogoresmobile.png');
 background-size: cover;
  @media only screen and (min-width: 768px) {
     background-position: center;
    
  }

  background-attachment: scroll;

  @media only screen and (min-width: 768px) {
    background-attachment: fixed;
    height: 50vh !important;
  }
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
background-position: center;


}

@media only screen and (max-width: 768px) {
  /* .hero-banner-next {
        background-attachment: initial;
  } */

}

.no-div {
background-image: url("../5--c2Vhbi1kaXhvbi1zZXJlbmE%3D.png");
background-image: url("../1--dG9tLWFtZXJ5LWxhdXJh.png");
background-image: url("../2--dG9tLWFtZXJ5LWxhdXJh.png");
background-image: url("../3--c2FtLXJhaW5lLXNhbS1yYWluZS1wbHVzb25l.png");
background-image: url("../4--c2FtLXJhaW5lLXNhbS1yYWluZS1wbHVzb25l.png");
background-image: url("../6--c2Vhbi1kaXhvbi1zZXJlbmE%3D.png");
background-image: url("../7--ZGF2aWQtY2FyZXR0ZS1hbGljaWEtbWFl.png");
background-image: url("../8--ZGF2aWQtY2FyZXR0ZS1hbGljaWEtbWFl.png");
background-image: url("../9--bWljaGVsbGUtYnJlbm5hbg%3D%3D.png");
background-image: url("../10--YXVudGllLWFuZ2VsYS1pYW4%3D.png");
background-image: url("../11--YXVudGllLWFuZ2VsYS1pYW4%3D.png");
background-image: url("../12--YXVudGllLWhlbGVu.png");
background-image: url("../13--YXVudGllLWphY2tpZS11bmNsZS10aW0%3D.png");
background-image: url("../14--YXVudGllLWphY2tpZS11bmNsZS10aW0%3D.png");
background-image: url("../15--YWRhbS1ub3J0b24tc2FtLW5vcnRvbg%3D%3D.png");
background-image: url("../16--YWRhbS1ub3J0b24tc2FtLW5vcnRvbg%3D%3D.png");
background-image: url("../17--Z2VvcmdpYS1sZWRidXJ5LW1hdHQ%3D.png");
background-image: url("../18--Z2VvcmdpYS1sZWRidXJ5LW1hdHQ%3D.png");
background-image: url("../19--bWFyaWEtZGFu.png");
background-image: url("../20--bWFyaWEtZGFu.png");
background-image: url("../21--YXVudGllLWNsYXJlLW1hcms%3D.png");
background-image: url("../22--YXVudGllLWNsYXJlLW1hcms%3D.png");
background-image: url("../23--c29waGllLXNuYXBlLWJlbg%3D%3D.png");
background-image: url("../24--c29waGllLXNuYXBlLWJlbg%3D%3D.png");
background-image: url("../25--aGFubmFoLWpha2U%3D.png");
background-image: url("../26--aGFubmFoLWpha2U%3D.png");
background-image: url("../27--Y2hyaXMtdGFzaA%3D%3D.png");
background-image: url("../28--Y2hyaXMtdGFzaA%3D%3D.png");
background-image: url("../29--bGV3aXMtbW9sbHk%3D.png");
background-image: url("../30--bGV3aXMtbW9sbHk%3D.png");
background-image: url("../31--ZW1pbHktemFjaC1pcmlz.png");
background-image: url("../32--ZW1pbHktemFjaC1pcmlz.png");
background-image: url("../33--ZW1pbHktemFjaC1pcmlz.png");
background-image: url("../34--YWxleC1tb29keS1taXJhbmRh.png");
background-image: url("../35--YWxleC1tb29keS1taXJhbmRh.png");
background-image: url("../36--amFrZS1iZWVjaC1hbm5h.png");
background-image: url("../37--amFrZS1iZWVjaC1hbm5h.png");
background-image: url("../38--bGV4LWhhZmZuZXItZ2VtbWE%3D.png");
background-image: url("../39--bGV4LWhhZmZuZXItZ2VtbWE%3D.png");
background-image: url("../40--ZGF2ZS1odXNzZWxsLWplbg%3D%3D.png");
background-image: url("../41--ZGF2ZS1odXNzZWxsLWplbg%3D%3D.png");
background-image: url("../42--bmlnZWwta2F0cmlu.png");
background-image: url("../43--bmlnZWwta2F0cmlu.png");
background-image: url("../44--aGFubmFoLXRvbS1yb2Jpbg%3D%3D.png");
background-image: url("../45--aGFubmFoLXRvbS1yb2Jpbg%3D%3D.png");
background-image: url("../46--aGFubmFoLXRvbS1yb2Jpbg%3D%3D.png");
background-image: url("../47--bHlkaWE%3D.png");
background-image: url("../48--bWFkZGll.png");
background-image: url("../49--ZWxsaXMtc29maWE%3D.png");
background-image: url("../50--ZWxsaXMtc29maWE%3D.png");
background-image: url("../51--Y2Fyb2xpbmUtamlt.png");
background-image: url("../52--Y2Fyb2xpbmUtamlt.png");
background-image: url("../53--bGl6LWxpei1wbHVzb25l.png");
background-image: url("../54--bGl6LWxpei1wbHVzb25l.png");
background-image: url("../55--a2lyc3R5LWthcmw%3D.png");
background-image: url("../56--a2lyc3R5LWthcmw%3D.png");
background-image: url("../57--a2F6LWthei1wbHVzb25l.png");
background-image: url("../58--a2F6LWthei1wbHVzb25l.png");
background-image: url("../59--ZW1pbHktbGV3aXMtYXJ0aHVy.png");
background-image: url("../60--ZW1pbHktbGV3aXMtYXJ0aHVy.png");
background-image: url("../61--ZW1pbHktbGV3aXMtYXJ0aHVy.png");
background-image: url("../62--Z2FpbC1yaWNoYXJk.png");
background-image: url("../63--Z2FpbC1yaWNoYXJk.png");
background-image: url("../64--cG9wcHktbHVrZQ%3D%3D.png");
background-image: url("../65--cG9wcHktbHVrZQ%3D%3D.png");
background-image: url("../66--aGVsZW4tbGVvbg%3D%3D.png");
background-image: url("../67--aGVsZW4tbGVvbg%3D%3D.png");
background-image: url("../68--aGFycmlldC1sZXdpcw%3D%3D.png");
background-image: url("../69--aGFycmlldC1sZXdpcw%3D%3D.png");
background-image: url("../70--em9lLXBldGU%3D.png");
background-image: url("../71--em9lLXBldGU%3D.png");
background-image: url("../72--bGF1cmEtbHVrZQ%3D%3D.png");
background-image: url("../73--bGF1cmEtbHVrZQ%3D%3D.png");
background-image: url("../74--d2VuZHktamFtZXM%3D.png");
background-image: url("../75--d2VuZHktamFtZXM%3D.png");
background-image: url("../76--am9lLW5laWw%3D.png");
background-image: url("../77--am9lLW5laWw%3D.png");
background-image: url("../78--sammie-bridalparty.png");
background-image: url("../79--sammie-bridalparty.png");
background-image: url("../80--kish-groomparty.png");
background-image: url("../81--kish-groomparty.png");
background-image: url("../82--murialjohn-groomparty.png");
background-image: url("../83--murialjohn-groomparty.png");
background-image: url("../84--tina-cam.png");
background-image: url("../85--tina-cam.png");
}

.hero-banner-next::before {}

.couple-background {
position: relative;
  display: block;
  padding: 72px 20px;

  width: 100%;

  position: relative;
}

.attendence-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #9F834C;
  padding: 8px;
  border-radius: 8px;
}

input[type="radio"]:checked + .attendence-label {
  background-color: #9F834C;
  color: white;
}

.desktop-iframe {
  width: 100% !important;
  height: 100% !important;
  min-width: 400px;
  min-height: 400px;
}

.couple-background::before {
  background-image: url('../mallorcaisland.png');
  background-size: contain; 
  background-repeat: no-repeat;
  /* background-color: #ECDED0; */
  background-position: top;
    content: '';
    position: absolute;
    top: 45px;
    width: 50%;
    height: 30%;
    left: 0;
    z-index: -1;
    opacity: 0.4;

    @media only screen and (min-width: 768px) {
      width: 300px;
    }

}

.section-2 .couple-background::before {
  background-image: url('../clink.png');
left: initial;
    right: 10px;
    top: 24px;
    opacity: 0.3;
    height: 116px;
}

.section-4 .couple-background::before {
  background-image: url('../oranges.png');
  background-size: contain; 
  background-repeat: no-repeat;
  /* background-color: #ECDED0; */
  background-position: top;
    content: '';
    position: absolute;
top: 9px;
    width: 100px;
    height: 100%;
    right: 130px;
    z-index: -1;
    opacity: 0.35;
  left: initial;
}

.section-5 .couple-background::before {
  background-image: url('../brideandgroom.png');
    left: initial;
    right: 63px;
    top: 68px;
    opacity: 0.4;
    height: 98px;

    @media only screen and (min-width: 768px) {
      left: 50%;
      right: initial;
      transform: translateX(-50%);
    }

}

.section-6 .couple-background::before {
  background-image: url('../rsvp.png');
    left: initial;
    left: 10px;
    top: 32px;
    opacity: 0.4;
    height: 95px;
}

.hero-banner__content {
  /* half width pane that covers the right side of the hero banner. background color is #E4D3C1 */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #E4D3C1;
  z-index: 0;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;

  }

}

.hero-banner__content {
  z-index: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.hero-banner__content h1 {
 
  z-index: 1;
  color: #9F834C;

  font-weight: 200;

}

.order-service__container {
  position: relative;
}

.order-service__container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 100%;
  background-size: cover;

  background-image: url('../ordersep.png');
}

.order-service__text-container {
  position: relative;
}
.order-service__text-container::before {

  background-image: url('../linesep.png');
      content: '';
    position: absolute;
    top: 50%;
    background-position: center;
    background-size: contain;
    right: 86px;
    background-repeat: no-repeat;
    width: 55px;
    height: 100%;
    z-index: 50;
    transform: translateY(-50%);
}
.order-service__text-container:not(.order-1)::before {

  background-image: url('../linesep.png');
      content: '';
    position: absolute;
    top: 50%;
    background-position: center;
    background-size: contain;
    left: 86px;
    background-repeat: no-repeat;
    width: 55px;
    height: 100%;
    z-index: 50;
    transform: translateY(-50%);
}



.hero-banner__content h2 {
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero-banner__content span:not(.period) {
  font-size: 24px;

}

.hero-banner__content .period {
@media only screen and (min-width: 768px) {
 height: 84px;
  }
@media only screen and (max-width: 768px) {
  width: 8px;
  height: 8px;

  svg {
    width: 8px;
    height: 8px;
  }
}
}

  



.hero-banner__overlay {
  /* background-color: rgba(0, 0, 0, 0.25); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section {
min-height: 50vh;
}

#dietary-requirements input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;

}

#dietary-requirements input[type="checkbox"]::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #9F834C;
  border-radius: 4px;
  background-color: white;
}

#dietary-requirements input[type="checkbox"]:checked::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #9F834C;
  border-radius: 4px;
  background-color: #9F834C;
}
</style>