import revive_payload_client_4sVQNw7RlN from "/tmp/build_7ed9ff6c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_7ed9ff6c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CaKIoANnI2 from "/tmp/build_7ed9ff6c/node_modules/nuxt/dist/app/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build_7ed9ff6c/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_7ed9ff6c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_7ed9ff6c/.nuxt/components.plugin.mjs";
import plugin_client_i8AMfKeYnY from "/tmp/build_7ed9ff6c/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/tmp/build_7ed9ff6c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_3AyO8nEfhE from "/tmp/build_7ed9ff6c/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_CaKIoANnI2,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  sentry_3AyO8nEfhE
]